import { useEffect, useState } from "react";
import Checkbox from "../components/checkbox";
import {
  Button,
  Container,
  HorizontalSpacer,
  Row,
  Select,
  VerticalSpacer
} from "../components/common/styles";
import Spinner from "../components/spinner";
import useGamesList from "../hooks/use-games-list";
import useRegionalGames from "../hooks/use-regional-games";
import PageLayout from "../layouts/page-layout";
import { isDefined } from "../utils/validation";

export default function ConfigurePage() {
  const [loading, setLoading] = useState(false);
  const [activeRegion, setActiveRegion] = useState("");
  const [availableGames, setAvailableGames] = useState([]);
  const { gamesList, isLoading } = useGamesList();
  const { regionalGamesList, isLoading: isRegionalGamesLoading } = useRegionalGames(123);

  function handleChange(event) {
    setActiveRegion(event.target.value);
  }

  function handleUpdate() {
    console.log(availableGames.filter((game) => game.checked));
    setLoading(true);
    setTimeout(() => setLoading(false), 1000);
  }

  function handleCheckboxClick(gameName) {
    setAvailableGames((previousGames) =>
      previousGames.map((game) =>
        game.name === gameName ? { ...game, checked: !game.checked } : game
      )
    );
  }

  const availableRegions = ["Hegyvidék", "Velence"];

  useEffect(() => {
    if (!isDefined(gamesList)) return;
    setAvailableGames(
      gamesList.map((game) => ({
        ...game,
        checked: !!regionalGamesList?.games.find((regionalGame) => regionalGame.name === game.name)
      }))
    );
  }, [gamesList, regionalGamesList?.games]);

  if (isLoading || isRegionalGamesLoading) return <Spinner dense />;

  return (
    <PageLayout>
      <h1>Update available games</h1>

      <Container>
        {loading ? (
          <Spinner dense />
        ) : (
          <div>
            <Row>
              <Select
                name="available-regions"
                id="available-regions"
                onChange={handleChange}
                value={activeRegion}
                style={{ minWidth: 200 }}
              >
                {availableRegions.map((availableRegion) => (
                  <option key={availableRegion} value={availableRegion}>
                    {availableRegion}
                  </option>
                ))}
              </Select>
              <HorizontalSpacer />
              <Button onClick={handleUpdate}>Update</Button>
            </Row>
            <VerticalSpacer />
            {availableGames.map((game) => (
              <div key={game.name} style={{ display: "flex", gap: 10, marginBottom: 10 }}>
                <Checkbox
                  checked={game.checked}
                  onChange={() => handleCheckboxClick(game.name)}
                  name={game.name}
                />
              </div>
            ))}
            {console.log(regionalGamesList)}
          </div>
        )}
      </Container>
    </PageLayout>
  );
}
