import { useKeycloak } from "@react-keycloak/web";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import chance from "chance";
import { isRegionInExperiments, isPhysician } from "../../utils/validation";
import Nav from "./navigation.styles";

const c = new chance();

function AuthenticationButton({ keycloak }) {
  const { t } = useTranslation();

  if (!keycloak.authenticated)
    return (
      <Nav.Item>
        <button type="button" onClick={() => keycloak.login()}>
          <Nav.Item.Image
            src="/images/nav/nav-profile-login.png"
            alt="user icon with green background"
          />
          <Nav.Item.Text>{t("navigation.login")}</Nav.Item.Text>
        </button>
      </Nav.Item>
    );

  return (
    <Nav.Item>
      <button type="button" onClick={() => keycloak.logout()}>
        <Nav.Item.Image
          src="/images/nav/nav-profile-logout.png"
          alt="user icon with red background"
        />
        <Nav.Item.Text>
          {t("navigation.logout")} ({keycloak.tokenParsed.preferred_username})
        </Nav.Item.Text>
      </button>
    </Nav.Item>
  );
}

function NavbarToggleButton({ handleToggleClick }) {
  return (
    <Nav.Toggle onClick={() => handleToggleClick()}>
      <span></span>
      <span></span>
      <span></span>
    </Nav.Toggle>
  );
}

export default function Navigation() {
  const { t } = useTranslation();
  const location = useLocation();
  const { keycloak, initialized: keycloakInitialized } = useKeycloak();
  const [isExpanded, setIsExpanded] = useState();

  const navigationItems = [
    {
      id: c.guid(),
      name: t("navigation.home"),
      url: "/",
      icon: "/images/nav/nav-home.png",
      isPermitted: true
    },
    {
      id: c.guid(),
      name: t("navigation.about"),
      url: "/about",
      icon: "/images/nav/nav-about.png",
      isPermitted: true
    },
    {
      id: c.guid(),
      name: t("navigation.experiments"),
      url: "/screening",
      icon: "/images/nav/nav-test.png",
      isPermitted: (isRegionInExperiments() || isPhysician(keycloak))
    },
    {
      id: c.guid(),
      name: t("navigation.games"),
      url: "/games",
      icon: "/images/nav/nav-games-shaped.png",
      isPermitted: (!isRegionInExperiments() || isPhysician(keycloak))
    },
    {
      id: c.guid(),
      name: t("navigation.profile"),
      url: "/profile",
      icon: "/images/nav/nav-stats.png",
      isPermitted: true
    },
    {
      id: c.guid(),
      name: t("navigation.subjects"),
      url: "/subjects",
      icon: "/images/nav/nav-subjects.png",
      isPermitted: isPhysician(keycloak)
    },
    {
      id: c.guid(),
      name: t("navigation.configure"),
      url: "/configure",
      icon: "/images/nav/nav-configure.png",
      isPermitted: isPhysician(keycloak)
    }
  ];

  function toggleNavbar() {
    setIsExpanded((previousExpanded) => !previousExpanded);
  }

  return (
    <Nav.Container>
      <Link to="/">
        <Nav.Item.Image
          src="/images/nav/nav-logo.png"
          alt=""
          style={{ width: "auto", height: 60, marginTop: 5 }}
        />
      </Link>
      <NavbarToggleButton handleToggleClick={toggleNavbar} />
      <Nav.List isExpanded={isExpanded}>
        {navigationItems.map(
          (navigationItem) =>
            navigationItem.isPermitted && (
              <Nav.Item
                key={navigationItem.id}
                onClick={() => toggleNavbar()}
                active={location.pathname === navigationItem.url}
              >
                <Link to={navigationItem.url}>
                  <Nav.Item.Image src={navigationItem.icon} alt={navigationItem.name} />
                  <Nav.Item.Text>{navigationItem.name}</Nav.Item.Text>
                </Link>
              </Nav.Item>
            )
        )}
        {keycloakInitialized && <AuthenticationButton keycloak={keycloak} />}
      </Nav.List>
    </Nav.Container>
  );
}
