import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import flatten from "lodash/flatten";
import useGamesHistory from "../../hooks/use-games-history";
import Widget from "./widget.styles";
import Spinner from "../spinner";
import ErrorPageContent from "../contents/error-page-content";
import { isDefined, isValidExperiment } from "../../utils/validation";
import { LOCALE_DATE_FORMAT_LONG } from "../../utils/constants";

function HistoryWidgetLoading({ title }) {
  return (
    <Widget style={{ flex: 1 }}>
      <Widget.Title>{title}</Widget.Title>
      <Spinner />
    </Widget>
  );
}

function HistoryWidgetError({ title }) {
  return (
    <Widget style={{ flex: 1 }}>
      <Widget.Title>{title}</Widget.Title>
      <ErrorPageContent disableBackButton />
    </Widget>
  );
}

function HistoryWidgetNoResult({ title, description }) {
  return (
    <Widget style={{ flex: 1 }}>
      <Widget.Title>{title}</Widget.Title>
      <p style={{ paddingLeft: 8 }}>{description}</p>
    </Widget>
  );
}

function HistoryWidgetEntry({ gameHistory }) {
  const { t } = useTranslation();

  function getFormattedDate(date) {
    return new Date(date).toLocaleDateString("hu", LOCALE_DATE_FORMAT_LONG);
  }

  return (
    <Widget.HistoryItem key={gameHistory.date}>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <span style={{ fontSize: 12 }}>{getFormattedDate(gameHistory.date)}</span>
        <span>{t(`games.${gameHistory.name}.title`)}</span>
      </div>
      <span style={{ flex: 0 }}>{gameHistory.order}</span>
    </Widget.HistoryItem>
  );
}

export default function HistoryWidget() {
  const { t } = useTranslation();
  const playerId = JSON.parse(localStorage.getItem("flags"))?.session?.playerId ?? null;
  const { gamesHistory, isLoading, isError } = useGamesHistory(playerId);

  function isInterrupted({ data }) {
    return !!data.interrupted;
  }

  const gamesHistoryTableData = useMemo(() => {
    if (!gamesHistory || gamesHistory.totalElements === 0) return [];

    const filteredGamesHistory = gamesHistory.content.filter(
      (gameHistory) => isValidExperiment(gameHistory.data.gameName) && !isInterrupted(gameHistory)
    );

    const unmappedGames = filteredGamesHistory.map((game) => game.data);
    const games = groupBy(unmappedGames, "gameName");

    const mappedGames = Object.keys(games).map((game) => {
      const gameHistory = games[game].map((gameEntry, index) => ({
        name: game,
        date: gameEntry.startTimeEpoch,
        order: games[game].length - index
      }));
      return gameHistory;
    });

    const sortedGames = orderBy(flatten(mappedGames), "date", "desc");

    return sortedGames;
  }, [gamesHistory]);

  if (isLoading) return <HistoryWidgetLoading title={t("page.experiments.history")} />;
  if (isError) return <HistoryWidgetError title={t("page.experiments.history")} />;
  if (!isDefined(gamesHistoryTableData))
    return (
      <HistoryWidgetNoResult
        title={t("page.experiments.history")}
        description={t("page.experiments.empty.history")}
      />
    );

  return (
    <Widget style={{ flex: 1 }}>
      <Widget.Title>{t("page.experiments.history")}</Widget.Title>
      <div style={{ maxHeight: 335, overflow: "auto" }}>
        {gamesHistoryTableData.map((gameHistory) => (
          <HistoryWidgetEntry
            key={gameHistory.date + gameHistory.order}
            gameHistory={gameHistory}
          />
        ))}
      </div>
    </Widget>
  );
}
