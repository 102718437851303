import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import io from "socket.io-client";
import { isDefined } from "../utils/validation";

const SOCKET_EVENT_SESSION = "session";
const SOCKET_EVENT_LIST_USERS = "list-users";
const SOCKET_EVENT_USER_CONNECTED = "user-connected";
const SOCKET_EVENT_USER_DISCONNECTED = "user-disconnected";
const SOCKET_EVENT_PRIVATE_MESSAGE = "private-message";

const SocketContext = createContext();

export function useSocket() {
  return useContext(SocketContext);
}

export function SocketProvider({ children }) {
  const { keycloak } = useKeycloak();
  const [socket, setSocket] = useState();
  const [userList, setUserList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState({});

  const selectedUser = useMemo(
    () => userList.find((user) => user.userId === selectedUserId),
    [userList, selectedUserId]
  );

  function initUser(user) {
    return {
      ...user,
      messages: [],
      hasNewMessages: false
    };
  }

  function sortUsers(usersToSort) {
    return usersToSort.sort((a, b) => {
      if (a.self) return -1;
      if (b.self) return 1;
      if (a.username < b.username) return -1;
      return a.username > b.username ? 1 : 0;
    });
  }

  function updateSelectedUser({ userId }) {
    setSelectedUserId(userId);
    setUserList((oldUsers) =>
      oldUsers.map((oldUser) => {
        if (oldUser.userId === userId) {
          oldUser.hasNewMessages = false;
        }

        return oldUser;
      })
    );
  }

  function updateUserList(updatedUserList) {
    setUserList(updatedUserList);
  }

  useEffect(() => {
    const chatSocket = io(window.__RUNTIME_CONFIG__.REACT_APP_CHAT_SERVICE_URI, {
      autoConnect: false,
      reconnectionAttempts: window.__RUNTIME_CONFIG__.REACT_APP_CHAT_SERVICE_MAX_RETRY ?? 5
    });
    setSocket(chatSocket);

    return () => chatSocket.close();
  }, []);

  useEffect(() => {
    if (!isDefined(socket)) return;

    const fetchData = async () => {
      const profile = await keycloak.loadUserProfile();
      if (userList.some((userListItem) => userListItem.email === profile.email)) return;

      const persistedSessionId = localStorage.getItem("sessionId");
      if (persistedSessionId) {
        socket.auth = { sessionId: persistedSessionId };
      }

      socket.auth = { ...socket.auth, email: profile.email };

      socket.connect();

      socket.on("connect", () => {
        setUserList((oldUsers) =>
          oldUsers.map((oldUser) => {
            if (oldUser.self) {
              oldUser.connected = true;
            }

            return oldUser;
          })
        );
      });

      socket.on("disconnect", () => {
        setUserList((oldUsers) =>
          oldUsers.map((oldUser) => {
            if (oldUser.self) {
              oldUser.connected = false;
            }

            return oldUser;
          })
        );
      });

      socket.on(SOCKET_EVENT_SESSION, ({ sessionId, userId }) => {
        socket.auth = { sessionId };
        localStorage.setItem("sessionId", sessionId);
        socket.userId = userId;
      });

      socket.on(SOCKET_EVENT_LIST_USERS, (users) => {
        const wrappedUsers = users.map((user) => {
          let found = false;
          for (let i = 0; i < userList.length; i++) {
            const existingUser = userList[i];
            if (existingUser.userId === user.userId) {
              existingUser.connected = user.connected;
              found = true;

              break;
            }
          }
          if (found) {
            return user;
          }

          const wrappedUser = initUser(user);
          wrappedUser.self = user.userId === socket.userId;
          return wrappedUser;
        });

        const sortedUsers = sortUsers(wrappedUsers);
        setUserList(sortedUsers);
      });

      socket.on(SOCKET_EVENT_USER_CONNECTED, (user) => {
        setUserList((oldUserList) => {
          let found = false;
          for (let i = 0; i < oldUserList.length; i++) {
            const oldUser = oldUserList[i];
            if (oldUser.userId === user.userId) {
              oldUser.connected = true;
              found = true;
              break;
            }
          }

          if (found) {
            return [...oldUserList];
          }

          const wrappedUser = initUser(user);
          return [...oldUserList, wrappedUser];
        });
      });

      socket.on(SOCKET_EVENT_USER_DISCONNECTED, (id) => {
        setUserList((oldUsers) =>
          oldUsers.map((oldUser) => {
            if (oldUser.userId === id) {
              oldUser.connected = false;
            }

            return oldUser;
          })
        );
      });

      socket.on(SOCKET_EVENT_PRIVATE_MESSAGE, ({ content, from, to }) => {
        setUserList((oldUsers) =>
          oldUsers.map((oldUser) => {
            const fromSelf = socket.userId === from;
            const currentTarget = fromSelf ? to : from;
            if (oldUser.userId === currentTarget) {
              oldUser.messages.push({ content, fromSelf });

              if (oldUser.userId !== selectedUserId) {
                oldUser.hasNewMessages = true;
              }
            }

            return oldUser;
          })
        );
      });
    };

    fetchData().catch(console.error);

    return () => {
      socket.off("connect_error");
      socket.off(SOCKET_EVENT_SESSION);
      socket.off(SOCKET_EVENT_LIST_USERS);
      socket.off(SOCKET_EVENT_USER_CONNECTED);
      socket.off(SOCKET_EVENT_PRIVATE_MESSAGE);
      socket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    console.log(userList);
  }, [userList]);

  const socketContextValues = {
    socket,
    userList,
    selectedUser,
    updateUserList,
    updateSelectedUser
  };

  return <SocketContext.Provider value={socketContextValues}>{children}</SocketContext.Provider>;
}
