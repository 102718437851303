import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Container } from "../components/common/styles";
import Spinner from "../components/spinner";
import PageLayout from "../layouts/page-layout";
import { languageMapper } from "../utils/validation";

export default function GamePage() {
  const { id } = useParams();
  const { i18n } = useTranslation();

  const gameUri = useMemo(
    () =>
      `/games/_frame/index.html#gameBase=/games/${id}/&gameName=${id}&lang=${
        languageMapper[i18n.resolvedLanguage]
      }`,
    [i18n.resolvedLanguage, id]
  );

  if (gameUri === "") {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  return (
    <PageLayout fullWidth>
      <Container style={{ display: "flex", minHeight: 845, padding: 0 }}>
        <iframe
          title="Corsi"
          src={gameUri}
          style={{ width: "100%", flexGrow: 1, boxSizing: "border-box" }}
        />
      </Container>
    </PageLayout>
  );
}
