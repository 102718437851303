import React from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${({ theme }) => theme.palette.main};
  stroke-width: 4px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${({ checked, theme }) => (checked ? theme.palette.primary : "transparent")};
  border: 3px solid ${({ theme }) => theme.palette.main};
  border-radius: 3px;
  transition: all ${({ theme }) => theme.transition.medium};

  :hover {
    background: ${({ theme }) => theme.palette.primary};
  }

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.palette.primary};
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }

  + label {
    padding-left: 10px;
  }
`;

const StyledStatelessCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent;
  border: 3px solid ${({ theme }) => theme.palette.main};
  border-radius: 3px;
  transition: all ${({ theme }) => theme.transition.medium};

  :hover {
    background: ${({ theme }) => theme.palette.primary};
  }

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.palette.primary};
  }

  ${HiddenCheckbox}:checked + & {
    background-color: ${({ theme }) => theme.palette.primary};
  }

  ${HiddenCheckbox} + & ${Icon} {
    visibility: hidden;
  }

  ${HiddenCheckbox}:checked + & ${Icon} {
    visibility: visible;
  }

  + label {
    padding-left: 10px;
  }
`;

export default function Checkbox({ checked, name, stateless, ...props }) {
  const checkboxName = `checkbox-${name}`;
  return (
    <label>
      <CheckboxContainer>
        <HiddenCheckbox checked={checked} id={checkboxName} {...props} />
        {stateless ? (
          <StyledStatelessCheckbox {...props}>
            <Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </StyledStatelessCheckbox>
        ) : (
          <StyledCheckbox checked={checked} {...props}>
            <Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </StyledCheckbox>
        )}

        <label htmlFor={checkboxName}>{name}</label>
      </CheckboxContainer>
    </label>
  );
}
