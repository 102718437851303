import styled from "styled-components";

const Head = styled.thead``;
const Body = styled.tbody``;

const Row = styled.tr`
  :last-child {
    td {
      border-bottom: 0;
    }
  }
  :nth-child(even) {
    background-color: #e5ded8;
  }
`;

const HeadCell = styled.th`
  text-align: left;
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;

  :last-child {
    border-right: 0;
  }
`;

const Cell = styled.td`
  text-align: left;
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;

  :last-child {
    border-right: 0;
  }
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-radius: 3px;
  border: 3px solid #402b21;
`;

Table.Head = Head;
Table.Body = Body;
Table.Row = Row;
Table.HeadCell = HeadCell;
Table.Cell = Cell;

export default Table;
