import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import {
  DOCUMENT_EXTENSION_CSV,
  DOCUMENT_EXTENSION_JSON,
  DOCUMENT_MIME_CSV,
  DOCUMENT_MIME_JSON,
  EDUCATION_HIGHER,
  EDUCATION_NOOP,
  EDUCATION_PRIMARY,
  EDUCATION_SECONDARY,
  GENDER_FEMALE,
  GENDER_MALE,
  LANGUAGE_EN,
  LANGUAGE_HU,
  REGION_HEGYVIDEK,
  REGION_OMIII,
  REGION_SEPPK,
  REGION_VELENCE,
  ROLE_PHYSICIAN,
  SUPPORTED_QUESTIONNAIRES,
  SUPPORTED_REGIONS,
  SUPPORTED_TESTS
} from "./constants";

export const fileTypeMapper = {
  [DOCUMENT_EXTENSION_JSON]: DOCUMENT_MIME_JSON,
  [DOCUMENT_EXTENSION_CSV]: DOCUMENT_MIME_CSV
};

export const languageMapper = {
  en: LANGUAGE_EN,
  hu: LANGUAGE_HU
};

export const educationMapper = {
  [EDUCATION_PRIMARY]: "primary",
  [EDUCATION_SECONDARY]: "secondary",
  [EDUCATION_HIGHER]: "higher",
  [EDUCATION_NOOP]: ""
};

export const genderMapper = {
  [GENDER_MALE]: 0,
  [GENDER_FEMALE]: 1
};

export const regionMapper = {
  [REGION_HEGYVIDEK]: 0,
  [REGION_VELENCE]: 1,
  [REGION_SEPPK]: 2,
  [REGION_OMIII]: 3
};

export function isDefined(value) {
  return !isNil(value) && !isEmpty(value);
}

export function isString(value) {
  return typeof value === "string" || value instanceof String;
}

export function isPhysician(keycloak) {
  return keycloak.authenticated && keycloak.realmAccess.roles.includes(ROLE_PHYSICIAN);
}

export function isRegionInExperiments() {
  const url = window.location.href;
  const domains = /:\/\/([^/]+)/.exec(url)[1].split(".");
  const region = SUPPORTED_REGIONS.find((currentRegion) => domains.includes(currentRegion));

  return typeof regionMapper[region] !== "undefined";
}

export function isGameInCategories(game, categories = []) {
  if (categories.length === 0) return true;

  return categories.some((category) => category === game);
}

export function isValidExperiment(experimentName) {
  return isGameInCategories(experimentName, SUPPORTED_TESTS);
}

export function isValidGame(gameName) {
  return !isGameInCategories(gameName, SUPPORTED_QUESTIONNAIRES);
}

export async function doesResourceExist(resourceUri) {
  const result = await fetch(resourceUri, { method: "HEAD" });
  console.log(result.ok);
  return result.ok;
}
