import { useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer";
import Navigation from "../components/navigation/navigation";
import Chat from "../components/chat";
import { isDefined } from "../utils/validation";

export default function Layout() {
  const { keycloak } = useKeycloak();
  const { i18n } = useTranslation();

  function isChatEnabled() {
    return (
      keycloak.authenticated && isDefined(window.__RUNTIME_CONFIG__.REACT_APP_CHAT_SERVICE_URI)
    );
  }

  useEffect(() => {
    if (!keycloak.authenticated) return;
    if (i18n.resolvedLanguage === keycloak.tokenParsed.locale) return;

    i18n.changeLanguage(keycloak.tokenParsed.locale);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak.tokenParsed?.locale]);

  return (
    <>
      <Navigation />
      <Outlet />
      {isChatEnabled() && <Chat />}
      <Footer />
    </>
  );
}
