import styled from "styled-components";

const Layout = styled.div``;

const UserList = styled.div`
  overflow-y: auto;
  background-color: ${({ theme }) => theme.palette.background};
`;

const UserListHeader = styled.h4`
  margin-bottom: 0;
  padding-left: 0.5rem;
`;

const Bottombar = styled(Layout)`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  z-index: 1020;
  margin-right: 75px;
`;

const Sidebar = styled(Layout)`
  height: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1010;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

Layout.UserList = UserList;
Layout.UserListHeader = UserListHeader;
Layout.Bottombar = Bottombar;
Layout.Sidebar = Sidebar;

export default Layout;
