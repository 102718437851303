import styled from "styled-components";
import { Row, Container } from "../common/styles";

const WidgetAvatar = styled.div`
  display: flex;
  border-radius: 3px;
  background-color: ${({ color, theme }) => color ?? theme.palette.primary};
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
`;

const WidgetItemInfo = styled.div`
  display: flex;
  border-radius: 3px;
  background-color: ${({ color, theme }) => color ?? theme.palette.transparent.secondary};
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: ${({ theme }) => theme.palette.secondary};
  }
`;

const WidgetItem = styled(Row)`
  cursor: pointer;
  padding: 6px;
  border-radius: 3px;
  user-select: none;
  flex-grow: 0;

  :hover {
    background-color: ${({ theme }) => theme.palette.shadow};
  }
`;

const WidgetTitle = styled.h3`
  margin: 10px 5px;
`;

const WidgetHistoryItem = styled.div`
  display: flex;
  padding: 8px 10px 6px 6px;
  border-radius: 3px;
  align-items: center;

  :hover {
    background-color: ${({ theme }) => theme.palette.shadow};
  }
`;

const Widget = styled(Container)`
  padding: 5px;
  flex: 0;
`;

Widget.Title = WidgetTitle;
Widget.Item = WidgetItem;
Widget.ItemInfo = WidgetItemInfo;
Widget.Avatar = WidgetAvatar;
Widget.HistoryItem = WidgetHistoryItem;

export default Widget;
