export const mwTheme = {
  palette: {
    main: "#402b21",
    primary: "#f7c4b8",
    secondary: "#c1d7e2",
    success: "#45cb85",
    danger: "#d62839",
    background: "#f5f2f0",
    shadow: "#e8dfdb",
    transparent: {
      main: "#402b2122",
      primary: "#f7c4b844",
      secondary: "#c1d7e266",
      background: "#f5f2f044"
    }
  },
  transition: {
    slow: "ease-out 350ms",
    medium: "ease-out 200ms",
    fast: "ease-out 150ms"
  }
};
