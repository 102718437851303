import { useTranslation } from "react-i18next";
import PageLayout from "../layouts/page-layout";

export default function AboutPage() {
  const { t } = useTranslation();

  return (
    <PageLayout>
      <h1>{t("page.about.title")}</h1>
      <p>{t("page.about.bodyOne")}</p>
      <p>{t("page.about.bodyTwo")}</p>
      <p>{t("page.about.bodyThree")}</p>
      <p>{t("page.about.bodyFour")}</p>
      <p>{t("page.about.bodyFive")}</p>
      <p>{t("page.about.bodySix")}</p>
      <p>{t("page.about.bodySeven")}</p>
      <p>{t("page.about.gamesLink")} <a href="https://agytorna.bme.hu">https://agytorna.bme.hu</a></p>
    </PageLayout>
  );
}
