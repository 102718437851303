export const ROLE_PHYSICIAN = "physician";

export const DOCUMENT_EXTENSION_JSON = ".json";
export const DOCUMENT_EXTENSION_CSV = ".csv";
export const DOCUMENT_MIME_JSON = "application/json";
export const DOCUMENT_MIME_CSV = "text/csv";

export const LANGUAGE_EN = "en_US";
export const LANGUAGE_HU = "hu_HU";

export const GAME_CATEGORY_ALL = "test-all-game";
export const GAME_CATEGORY_ATTENTION = "test-attention-game";
export const GAME_CATEGORY_EXECUTIVE = "test-executive-function-game";
export const GAME_CATEGORY_LEARNING = "test-learning-ability-game";
export const GAME_CATEGORY_PLANNING = "test-planning-function-game";
export const GAME_CATEGORY_REACTION = "test-reaction-speed-game";
export const GAME_CATEGORY_MEMORY = "test-working-memory-game";
export const GAME_CATEGORY_TEST = "test-game";

export const GAME_SUPPORTED_MOBILE = "game-supported-device-mobile";
export const GAME_SUPPORTED_DESKTOP = "game-supported-device-desktop";

export const EDUCATION_PRIMARY = "education_primary";
export const EDUCATION_SECONDARY = "education_secondary";
export const EDUCATION_HIGHER = "education_higher";
export const EDUCATION_NOOP = "education_noop";

export const GENDER_FEMALE = "gender_female";
export const GENDER_MALE = "gender_male";
export const GENDER_NOOP = "gender_noop";

export const REGION_HEGYVIDEK = "hegyvidek";
export const REGION_VELENCE = "velence";
export const REGION_UJBUDA = "ujbuda";
export const REGION_SEPPK = "seppk";
export const REGION_OMIII = "omiii";

export const SUPPORTED_REGIONS = [REGION_HEGYVIDEK, REGION_UJBUDA, REGION_SEPPK, REGION_OMIII];

export const TEST_BUNNIES = "bunnies";
export const TEST_PAL = "paltest";
export const TEST_PLANAR = "planartest";
export const TEST_MOOD = "moodtest";
export const TEST_RISK = "risktest";

export const SUPPORTED_TESTS = [TEST_BUNNIES, TEST_PAL, TEST_PLANAR, TEST_MOOD, TEST_RISK];
export const SUPPORTED_QUESTIONNAIRES = [TEST_MOOD, TEST_RISK];

export const LOCALE_DATE_FORMAT_LONG = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit"
};
