import styled from "styled-components";

const UserAvatar = styled.div`
  position: relative;
  width: 35px;
  height: 35px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, hasNewMessages }) =>
    hasNewMessages ? theme.palette.secondary : theme.palette.primary};
  border-radius: 5px;
  box-shadow: ${({ theme, isSelected }) =>
    isSelected ? `0 0 0 3px inset ${theme.palette.main}` : "none"};
`;

const UserStatus = styled.div`
  position: absolute;
  height: 5px;
  width: 5px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.palette.success : theme.palette.danger};
  border: 2px solid ${({ theme }) => theme.palette.background};
  border-bottom-left-radius: 5px;
  bottom: 0;
  left: 0;
`;

const Username = styled.p`
  word-break: break-word;
  margin: 0;
`;

const User = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 0.75rem 1rem;

  &:hover {
    background-color: ${({ theme }) => theme.palette.transparent.primary};
  }
`;

User.Avatar = UserAvatar;
User.Status = UserStatus;
User.Name = Username;

export default User;
