import { useTranslation } from "react-i18next";
import PageLayout from "../layouts/page-layout";

export default function ImpressumPage() {
  const { t } = useTranslation();

  return (
    <PageLayout>
      <h3>{t("page.impressum.title")}</h3>
      <p>{t("page.impressum.bodyOne")}</p>
      <p>{t("page.impressum.bodyTwo")}</p>
      <p>{t("page.impressum.bodyThree")}</p>
      <p>{t("page.impressum.contact")}</p>
    </PageLayout>
  );
}
