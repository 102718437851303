import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "../layouts/page-layout";
import Spinner from "../components/spinner";
import useGamesList from "../hooks/use-games-list";
import GameCard from "../components/game/game-card";
import {
  GAME_CATEGORY_ALL,
  GAME_CATEGORY_ATTENTION,
  GAME_CATEGORY_EXECUTIVE,
  GAME_CATEGORY_LEARNING,
  GAME_CATEGORY_PLANNING,
  GAME_CATEGORY_REACTION,
  GAME_CATEGORY_MEMORY
} from "../utils/constants";
import { isDefined } from "../utils/validation";
import { Chip, Gap, VerticalSpacer } from "../components/common/styles";
import ErrorPageContent from "../components/contents/error-page-content";

export default function GamesPage() {
  const { t } = useTranslation();
  const { gamesList, isLoading, isError } = useGamesList();
  const [activeCategory, setActiveCategory] = useState(GAME_CATEGORY_ALL);

  const availableCategories = [
    { id: GAME_CATEGORY_ALL, label: t("category.game.all") },
    { id: GAME_CATEGORY_EXECUTIVE, label: t("category.game.executive") },
    { id: GAME_CATEGORY_ATTENTION, label: t("category.game.attention") },
    { id: GAME_CATEGORY_MEMORY, label: t("category.game.memory") },
    { id: GAME_CATEGORY_PLANNING, label: t("category.game.planning") },
    { id: GAME_CATEGORY_REACTION, label: t("category.game.reaction") },
    { id: GAME_CATEGORY_LEARNING, label: t("category.game.learning") }
  ];

  const filteredGameList = useMemo(() => {
    if (!isDefined(gamesList)) return [];
    if (activeCategory === GAME_CATEGORY_ALL) return gamesList;

    return gamesList.filter((game) => game.categories.includes(activeCategory));
  }, [gamesList, activeCategory]);

  function handleCategoryClick(category) {
    setActiveCategory(category);
  }

  if (isLoading) return <Spinner dense />;
  if (isError) return <ErrorPageContent />;

  return (
    <PageLayout>
      <h1>{t("page.games.title")}</h1>
      <Gap>
        {availableCategories.map((availableCategory) => (
          <Chip
            key={availableCategory.id}
            onClick={() => handleCategoryClick(availableCategory.id)}
            active={activeCategory === availableCategory.id}
          >
            {availableCategory.label}
          </Chip>
        ))}
      </Gap>
      <VerticalSpacer />
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
        {filteredGameList.map((game) => (
          <GameCard key={game.name} gameId={game.name} />
        ))}
      </div>
    </PageLayout>
  );
}
