import { useTranslation } from "react-i18next";
import PageLayout from "../layouts/page-layout";

export default function InfoPage() {
  const { t } = useTranslation();
  return (
    <PageLayout>
      <h1>{t("page.info.title")}</h1>
      <p>{t("page.info.bodyOne")}</p>
      <p>{t("page.info.bodyTwo")}</p>
      <p>{t("page.info.bodyThree")}</p>
      <p>{t("page.info.bodyFour")}</p>
      <p>{t("page.info.bodyFive")}</p>
      <p>{t("page.info.bodySix")}</p>
      <p>{t("page.info.bodySeven")}</p>
    </PageLayout>
  );
}
