export default function PageLayout({ children, fullWidth }) {
  return (
    <div
      style={{
        padding: "0.5rem 2rem",
        maxWidth: fullWidth ? "auto" : 962,
        marginLeft: "auto",
        marginRight: "auto"
      }}
    >
      {children}
    </div>
  );
}
