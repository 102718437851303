import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PageLayout from "../layouts/page-layout";
import useGameInfo from "../hooks/use-game-info";
import Spinner from "../components/spinner";
import { Button, Container, Row } from "../components/common/styles";
import ErrorPageContent from "../components/contents/error-page-content";
import GamesWidget from "../components/widgets/games-widget";
import QuestionnaireWidget from "../components/widgets/questionnaire-widget";
import HistoryWidget from "../components/widgets/history-widget";
import { languageMapper } from "../utils/validation";
import urlcat from "urlcat";

const NoSelectedItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function NoSelectedItems() {
  const { t } = useTranslation();
  return (
    <NoSelectedItem>
      <img src="images/no-selection.png" alt="Empty document" />
      <h1 style={{ marginBottom: 0 }}>{t("page.experiments.empty.title")}</h1>
      <p style={{ maxWidth: 600 }}>{t("page.experiments.empty.description")}</p>
    </NoSelectedItem>
  );
}

function CloseButton({ handleClose }) {
  const { t } = useTranslation();
  return (
    <Button
      style={{
        position: "absolute",
        right: 0,
        padding: 10,
        borderTop: 0,
        borderRight: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: 0
      }}
      onClick={handleClose}
    >
      {t("page.experiments.close")}
    </Button>
  );
}

function MainViewVideo({ resourceUri, isVideoLoading, isVideoError }) {
  const { t } = useTranslation();

  if (isVideoLoading) return <Spinner />;
  if (isVideoError) return "";

  return (
    <div key={resourceUri}>
      <video controls width="600">
        <source src={resourceUri} />
        <p>
          {t("error.support.video", { resourceUri: urlcat(window.location.origin, resourceUri) })}
        </p>
      </video>
    </div>
  );
}

function MainView({ activeStep, setActiveStep }) {
  const { t, i18n } = useTranslation();
  const { gameInfo, gameVideoUri, isLoading, isError, isVideoLoading, isVideoError } = useGameInfo(
    activeStep.replace("-info", "")
  );

  const uri = useMemo(
    () =>
      `/games/_frame/index.html#gameBase=/games/${activeStep}/&gameName=${activeStep}&lang=${
        languageMapper[i18n.resolvedLanguage]
      }`,
    [i18n.resolvedLanguage, activeStep]
  );

  useEffect(() => {
    const removeCurrentGame = (event) => {
      if (event.data === "exit") setActiveStep("");
    };

    window.addEventListener("message", removeCurrentGame, false);
    return () => {
      window.removeEventListener("message", removeCurrentGame, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!activeStep || activeStep.includes("-info")) {
      localStorage.removeItem("gameMode");
      return;
    }

    localStorage.setItem("gameMode", "experiment");
  }, [activeStep]);

  if (!activeStep) return <NoSelectedItems />;
  if (isLoading) return <Spinner />;
  if (isError)
    return (
      <>
        <CloseButton handleClose={() => setActiveStep("")} />
        <ErrorPageContent style={{ width: "100%" }} disableBackButton />
      </>
    );

  if (activeStep.includes("-info"))
    return (
      <>
        <CloseButton handleClose={() => setActiveStep("")} />
        <div style={{ width: "100%", padding: 35, overflowY: "scroll" }}>
          <h3>{t("page.experiments.info.description")}</h3>
          <p style={{ whiteSpace: "pre-wrap", lineHeight: "1.6875rem" }}>{gameInfo}</p>

          <MainViewVideo
            resourceUri={gameVideoUri}
            isVideoLoading={isVideoLoading}
            isVideoError={isVideoError}
          />
        </div>
      </>
    );

  return (
    <iframe
      title={activeStep}
      src={uri}
      style={{ width: "100%", flexGrow: 1, boxSizing: "border-box", border: "none" }}
    />
  );
}

export default function ExperimentPage() {
  const [activeStep, setActiveStep] = useState("");

  function handleClick(id) {
    setActiveStep(id);
  }

  function handleInfoClick(event, id) {
    event.stopPropagation();
    setActiveStep(`${id}-info`);
  }

  return (
    <PageLayout fullWidth>
      <Row gap={1}>
        <div
          style={{
            width: "33%",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem"
          }}
        >
          <GamesWidget handleClick={handleClick} handleInfoClick={handleInfoClick} />
          <QuestionnaireWidget handleClick={handleClick} handleInfoClick={handleInfoClick} />
          <HistoryWidget />
        </div>
        <Container
          style={{
            position: "relative",
            display: "flex",
            minHeight: 600,
            height: "calc(100vh - 200px)",
            padding: 0
          }}
        >
          <MainView activeStep={activeStep} setActiveStep={setActiveStep} />
        </Container>
      </Row>
    </PageLayout>
  );
}
