import { useMemo } from "react";
import useSWR from "swr";
import urlcat from "urlcat";

const fetcher = (url) =>
  fetch(url, {
    mode: "cors"
  }).then((res) => res.json());

export default function useRegionalGames(region) {
  const gamesApiUri = useMemo(
    () =>
      urlcat(`${window.__RUNTIME_CONFIG__.REACT_APP_GAMES_API_URI}/api/regional-games/:regionId`, {
        regionId: region
      }),
    [region]
  );

  console.log(gamesApiUri);
  const { data, error } = useSWR(gamesApiUri, fetcher);

  return {
    regionalGamesList: data,
    isLoading: !error && !data,
    isError: error
  };
}
