import { useEffect, useRef, useState } from "react";
import { useSocket } from "../../contexts/socket-provider";
import { isDefined } from "../../utils/validation";
import { Gap } from "../common/styles";
import Divider from "../divider";
import Message from "./styles/message.styles";
import Panel from "./styles/panel.styles";

const mockMessages = [
  { id: 1, text: "Hello, hogy vagy?" },
  { id: 2, text: "Mikor játszunk?" },
  { id: 3, text: "Gyere játszani!" },
  { id: 4, text: "Gratula!" }
];

function ChatConversation({ messages = [] }) {
  const { userList } = useSocket();
  const conversationBottomRef = useRef(null);

  function isOwnMessage(message) {
    return message.fromSelf;
  }

  useEffect(() => {
    conversationBottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [userList]);

  return (
    <Panel.Body>
      {messages.map((message) =>
        isOwnMessage(message) ? (
          <Message.Outgoing key={message.content.id}>{message.content.text}</Message.Outgoing>
        ) : (
          <Message.Incoming key={message.content.id}>{message.content.text}</Message.Incoming>
        )
      )}
      <div ref={conversationBottomRef}></div>
    </Panel.Body>
  );
}

function ChatInput({ message, handleSubmit, ...rest }) {
  function handleButtonClick() {
    handleSubmit(message);
  }

  return (
    <Message.Preview onClick={handleButtonClick} className="chat-input-option" {...rest}>
      {message}
    </Message.Preview>
  );
}

function ChatInputContainer({ handleSubmit }) {
  return (
    <Panel.Footer>
      {mockMessages.map((message) => (
        <ChatInput key={message.id} message={message.text} handleSubmit={handleSubmit} />
      ))}
    </Panel.Footer>
  );
}

function ChatPanelHeader({
  username,
  isHidden,
  handleCloseButtonClick,
  handleCollapseButtonClick
}) {
  return (
    <Panel.Header>
      {!isHidden && <Panel.HeaderText>{username}</Panel.HeaderText>}
      <Gap>
        {!isHidden && (
          <Panel.HeaderButton onClick={handleCollapseButtonClick}>_</Panel.HeaderButton>
        )}
        <Panel.HeaderButton onClick={handleCloseButtonClick} disabled>
          x
        </Panel.HeaderButton>
      </Gap>
    </Panel.Header>
  );
}

function ChatPanelBody({ messages, handleSubmit, isHidden, isCollapsed }) {
  if (isCollapsed || isHidden) return "";

  return (
    <>
      <ChatConversation messages={messages} />
      <div>
        <Divider />
        <ChatInputContainer handleSubmit={handleSubmit} />
      </div>
    </>
  );
}

export default function ChatPanel() {
  const { socket, selectedUser } = useSocket();
  const [isCollapsed, setIsCollapsed] = useState(isDefined(selectedUser));
  const [isHidden, setIsHidden] = useState(false);

  function handleCollapseButtonClick() {
    setIsCollapsed((prev) => !prev);
  }

  function handleCloseButtonClick() {
    setIsHidden((prev) => !prev);
  }

  function handleSubmit(message) {
    if (!isDefined(selectedUser)) return;

    const formattedMessage = {
      id: `${socket.id}-${Date.now()}`,
      text: message
    };

    socket.emit("private-message", { content: formattedMessage, to: selectedUser.userId });
  }

  if (!isDefined(selectedUser)) return "";

  return (
    <Panel
      style={{
        ...(isCollapsed && { height: 48 }),
        ...(isHidden && { height: "0", width: "47px" })
      }}
    >
      <ChatPanelHeader
        username={selectedUser.username}
        isHidden={isHidden}
        handleCollapseButtonClick={handleCollapseButtonClick}
        handleCloseButtonClick={handleCloseButtonClick}
      />
      <ChatPanelBody
        isHidden={isHidden}
        isCollapsed={isCollapsed}
        messages={selectedUser.messages}
        handleSubmit={handleSubmit}
      />
    </Panel>
  );
}
