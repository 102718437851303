import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import { ThemeProvider } from "styled-components";
import Layout from "./layouts/layout";
import HomePage from "./pages/home-page";
import ProfilePage from "./pages/profile-page";
import AboutPage from "./pages/about-page";
import InfoPage from "./pages/info-page";
import ImpressumPage from "./pages/impressum-page";
import TermsPage from "./pages/terms-page";
import GamePage from "./pages/game-page";
import GamesListPage from "./pages/games-list-page";
import GameInfoPage from "./pages/game-info-page";
import SubjectsPage from "./pages/subjects-page";
import ConfigurePage from "./pages/configure-page";
import ExperimentPage from "./pages/experiment-page";
import { mwTheme } from "./components/common/theme";
import PrivateRoute from "./utils/private-route";
import keycloakConfig from "./utils/keycloak";
import PrivateRoutePhysician from "./utils/private-route-physician";
import { educationMapper, genderMapper, regionMapper } from "./utils/validation";
import { SUPPORTED_REGIONS } from "./utils/constants";

function StatelessApp({ authSuccess }) {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (!authSuccess) return;

    async function loginUserToBackend() {
      const userProfile = await keycloak.loadUserProfile();
      const domains = /:\/\/([^/]+)/.exec(window.location.href)[1].split(".");
      const region = SUPPORTED_REGIONS.find((currentRegion) => domains.includes(currentRegion));

      const mappedUserProfile = {
        subject: userProfile.id,
        mail: userProfile.email,
        name: userProfile.email,
        birthYear: parseInt(userProfile.attributes.birthyear[0], 10),
        education: educationMapper[userProfile.attributes.education[0]],
        sex: genderMapper[userProfile.attributes.gender[0]],
        region: regionMapper[region] ?? 99999
      };

      const response = await fetch("/api/users/login", {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(mappedUserProfile)
      });

      if (!response.ok) return;

      const session = await response.json();

      localStorage.setItem("flags", JSON.stringify({ session }));
      window.mwApiHeaders = { "X-Session-Token": session.token };
    }

    loginUserToBackend();
  }, [authSuccess, keycloak]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route
            path="/configure"
            element={
              <PrivateRoutePhysician>
                <ConfigurePage />
              </PrivateRoutePhysician>
            }
          />
          <Route
            path="/screening"
            element={
              <PrivateRoute>
                <ExperimentPage />
              </PrivateRoute>
            }
          />
          <Route path="/games" element={<GamesListPage />} />
          <Route path="/games/:id" element={<GamePage />} />
          <Route path="/games/:id/info" element={<GameInfoPage />} />
          <Route path="/info" element={<InfoPage />} />
          <Route path="/impressum" element={<ImpressumPage />} />
          <Route path="/terms-and-policies" element={<TermsPage />} />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <ProfilePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/subjects"
            element={
              <PrivateRoutePhysician>
                <SubjectsPage />
              </PrivateRoutePhysician>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  const [authSuccess, setAuthSuccess] = useState(false);

  const handleAuthenticaticationEvents = (event, error) => {
    if (event === "onAuthSuccess") setAuthSuccess(true);
  };

  useEffect(() => {
    if (!window.__RUNTIME_CONFIG__.REACT_APP_TITLE) return;
    document.title = window.__RUNTIME_CONFIG__.REACT_APP_TITLE;
  }, []);

  return (
    <ThemeProvider theme={mwTheme}>
      <ReactKeycloakProvider authClient={keycloakConfig} onEvent={handleAuthenticaticationEvents}>
        <StatelessApp authSuccess={authSuccess} />
      </ReactKeycloakProvider>
    </ThemeProvider>
  );
}

export default App;
