import { useEffect, forwardRef, useRef } from "react";
import { useTable, useRowSelect } from "react-table";
import { isDefined } from "../../utils/validation";
import { Overflow } from "../common/styles";
import Table from "./table.styles";
import TableHeader from "./table-header";
import StyledCheckbox from "../checkbox";

const Checkbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <div ref={resolvedRef}>
      <StyledCheckbox {...rest} />
    </div>
  );
});

function DataTableBody({ getTableProps, getTableBodyProps, headerGroups, rows, prepareRow }) {
  return (
    <Table {...getTableProps()}>
      <Table.Head>
        {headerGroups.map((headerGroup) => (
          <Table.Row {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Table.HeadCell {...column.getHeaderProps()}>
                {column.render("Header")}
              </Table.HeadCell>
            ))}
          </Table.Row>
        ))}
      </Table.Head>
      <Table.Body {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <Table.Row {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <Table.Cell {...cell.getCellProps()}>{cell.render("Cell")}</Table.Cell>;
              })}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

export function DataTableSelect({ title = "Data table", data = [], columns = [] }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows } =
    useTable({ columns, data }, useRowSelect, (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Column for the selections
        {
          id: "selection",
          // Use the table's getToggleAllRowsSelectedProps
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          // Use the individual row's getToggleRowSelectedProps
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />
        },
        ...columns
      ]);
    });

  if (!isDefined(data)) return "Data is not defined.";
  if (!isDefined(columns)) return "Columns are not defined.";

  return (
    <>
      <TableHeader title={title} selectedRows={selectedFlatRows} />

      <Overflow>
        <DataTableBody
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
        />
      </Overflow>
    </>
  );
}

export function DataTable({ title = "Data table", data = [], columns = [] }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  });

  if (!isDefined(data)) return "Data is not defined.";
  if (!isDefined(columns)) return "Columns are not defined.";

  return (
    <>
      <TableHeader title={title} />

      <Overflow>
        <DataTableBody
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
        />
      </Overflow>
    </>
  );
}
