import useSWR from "swr";

const fetcher = (url) =>
  fetch(url, {
    mode: "cors"
  }).then((res) => res.json());

export default function useGamesList() {
  const gamesApiUri = `${window.__RUNTIME_CONFIG__.REACT_APP_GAMES_API_URI}/api/games`;
  const { data, error } = useSWR(gamesApiUri, fetcher);

  return {
    gamesList: data,
    isLoading: !error && !data,
    isError: error
  };
}
