export default function Divider({ vertical = false }) {
  return (
    <hr
      style={{
        margin: 0,
        WebkitFlexShrink: 0,
        color: "rgba(0, 0, 0, 0.6)",
        backgroundColor: "rgba(0, 0, 0, 0.12)",
        msFlexNegative: 0,
        lineHeight: 1.5,
        marginTop: "1rem",
        marginBottom: "1rem",
        flexShrink: 0,
        borderWidth: 0,
        borderStyle: "solid",
        borderColor: "rgba(0, 0, 0, 0.12)",
        borderBottomWidth: 0,
        height: vertical ? "auto" : 1,
        borderRightWidth: "thin",
        WebkitAlignSelf: "stretch",
        msFlexItemAlign: "stretch",
        alignSelf: "stretch",
      }}
    />
  );
}
