import { useTranslation } from "react-i18next";
import PageLayout from "../layouts/page-layout";

export default function HomePage() {
  const { t } = useTranslation();
  return (
    <PageLayout>
      <h1>{t("page.home.title")}</h1>
      <p>{t("page.home.bodyOne")}</p>
      <p>{t("page.home.bodyTwo")}</p>
      <p>{t("page.home.bodyThree")}</p>
      <p>{t("page.home.bodyFour")}</p>
    </PageLayout>
  );
}
