import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 12
      }}
    >
      <Link to="/info">{t("navigation.info")}</Link>
      <span style={{ padding: "1rem" }}>|</span>
      <Link to="/terms-and-policies">{t("navigation.terms")}</Link>
      <span style={{ padding: "1rem" }}>|</span>
      <Link to="/impressum">{t("navigation.impressum")}</Link>
    </div>
  );
}
