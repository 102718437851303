import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import urlcat from "urlcat";
import { isDefined, languageMapper } from "../utils/validation";

const fetcher = (url) => {
  return fetch(url, {
    mode: "cors"
  }).then((res) => {
    if (!res.ok) {
      const error = new Error("An error occurred while fetching the data.");
      error.status = res.status;
      throw error;
    }
    if (res.url.endsWith(".json")) return res.json();

    return res.text();
  });
};

export default function useGameInfo(id) {
  const { i18n } = useTranslation();

  const gameTranslationUri = useMemo(
    () =>
      id
        ? urlcat("/games/_i18n/:id-:lang.json", {
            id,
            lang: languageMapper[i18n.resolvedLanguage]
          })
        : "",
    [id, i18n.resolvedLanguage]
  );

  const gameDescriptionUri = useMemo(
    () => (id ? urlcat("/games/:id/src/description.txt", { id }) : ""),
    [id]
  );

  const gameVideoUri = useMemo(() => (id ? urlcat("/games/:id/src/video.webm", { id }) : ""), [id]);

  const { data: translation, error: translationError } = useSWR(
    gameTranslationUri ? gameTranslationUri : "",
    fetcher
  );

  const { data: description, error: descriptionError } = useSWR(
    gameDescriptionUri ? gameDescriptionUri : "",
    fetcher
  );

  const { data: video, error: videoError } = useSWR(gameVideoUri ? gameVideoUri : "", fetcher, {
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      if (error.status === 404) return;
      if (retryCount >= 10) return;

      setTimeout(() => revalidate({ retryCount }), 5000);
    }
  });

  if (translationError || descriptionError || !isDefined(translation)) {
    return { gameInfo: "", isLoading: false, isError: translationError };
  }

  const arrayOfKeys = description?.replaceAll('"', "").split("\n");
  const translatedValues = arrayOfKeys?.map((key) => translation[key] ?? key);

  return {
    gameVideoUri,
    gameInfo: translatedValues?.join("\n"),
    isLoading: !translationError && !descriptionError && (!translation || !description),
    isVideoLoading: !videoError && !video,
    isVideoError: videoError,
    isError: translationError
  };
}
