import dayjs from "dayjs";
import chance from "chance";
import ProgressBar from "../components/progress-bar";

const c = new chance();

export function createChatUserMock(index = c.integer()) {
  return {
    id: index,
    name: c.name(),
    socketId: c.guid()
  };
}
export function createChatUserMockList(length = 10) {
  return Array.from({ length }, (_, index) => createChatUserMock(index));
}

export function createUserMock() {
  return {
    name: c.name(),
    email: c.email(),
    yearOfBirth: c.year(),
    education: c.pickone(["N/A", "BSc", "MSc", "PhD"]),
    lastPlayed: dayjs(c.date()).format("YYYY.MM.DD HH:mm:ssZ")
  };
}

export function createUserListMock(length = 3) {
  return Array.from({ length }, () => createUserMock());
}

export function createGameHistoryMock() {
  return {
    game: c.word(),
    numberOfGamesPlayed: c.integer({ min: 0, max: 1000 }),
    averagePoints: <ProgressBar points={c.integer({ min: 0, max: 1000 })} />
  };
}

export function createGameHistoryListMock(length = 5) {
  return Array.from({ length }, () => createGameHistoryMock());
}
