import styled from "styled-components";
import { Button } from "../../common/styles";

const PanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem;
`;

const PanelHeaderText = styled.h4`
  margin: 0;
`;

const PanelHeaderButton = styled(Button)`
  padding: 0.25rem 0.5rem;

  :disabled {
    cursor: not-allowed;
    background-color: rgba(239, 239, 239, 0.3);
    color: rgba(16, 16, 16, 0.3);
    border-color: rgba(118, 118, 118, 0.3);
  }
`;

const PanelBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 10px;
  flex-grow: 1;
  padding: 0.75rem;
  border-top: 3px solid #402b21;
`;

const PanelFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0 0.75rem 0.75rem 0.75rem;
`;

const Panel = styled.div`
  font-size: 16px;
  margin-bottom: 0.75rem;
  background-color: #fff;
  border: 3px solid ${({ theme }) => theme.palette.main};
  border-radius: 3px;
  width: 320px;
  height: 500px;
  display: flex;
  flex-direction: column;
  transition: height ${({ theme }) => theme.transition.medium};
`;

Panel.Header = PanelHeader;
Panel.HeaderText = PanelHeaderText;
Panel.HeaderButton = PanelHeaderButton;
Panel.Body = PanelBody;
Panel.Footer = PanelFooter;

export default Panel;
