import { useMemo } from "react";
import { useKeycloak } from "@react-keycloak/web";
import ErrorPageContent from "../components/contents/error-page-content";
import PageLayout from "../layouts/page-layout";
import Spinner from "../components/spinner";
import { useSubjects } from "../hooks/use-subjects";
import { createUserListMock } from "../utils/mocks";
import { useTranslation } from "react-i18next";
import { DataTableSelect } from "../components/data-table";

export default function SubjectsPage() {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { subjects, isLoading, isError } = useSubjects();

  const data = useMemo(() => createUserListMock(20), []);
  const columns = useMemo(
    () => [
      {
        Header: t("table.subject.name"),
        accessor: "name"
      },
      {
        Header: t("table.subject.email"),
        accessor: "email"
      },
      {
        Header: t("table.subject.yearOfBirth"),
        accessor: "yearOfBirth"
      },
      {
        Header: t("table.subject.education"),
        accessor: "education"
      },
      {
        Header: t("table.subject.lastPlayed"),
        accessor: "lastPlayed"
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!keycloak.authenticated) return <ErrorPageContent />;

  if (isError) return <ErrorPageContent />;
  if (isLoading) return <Spinner />;

  // TODO: replace mocks with actual subject data
  console.log(subjects);

  return (
    <PageLayout>
      <DataTableSelect data={data} columns={columns} />
    </PageLayout>
  );
}
