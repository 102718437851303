import styled from "styled-components";

const Message = styled.div`
  display: block;
  padding: 10px;
  border-radius: 5px;
  max-width: 75%;
  word-break: break-word;
  transition: background-color ${({ theme }) => theme.transition.medium};
`;

const IncomingMessage = styled(Message)`
  align-self: start;
  background-color: #e8dfdb;
`;

const OutgoingMessage = styled(Message)`
  align-self: end;
  background-color: #c1d7e2;
`;

const PreviewMessage = styled(Message)`
  padding: 0.5rem 0.75rem;
  background-color: ${({ theme }) => theme.palette.primary};
  user-select: none;
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.palette.main};

  :hover {
    background-color: #f5b5a6;
  }
`;

Message.Incoming = IncomingMessage;
Message.Outgoing = OutgoingMessage;
Message.Preview = PreviewMessage;

export default Message;
