import { getMonogram } from "../../utils/helper";
import User from "./styles/user.styles";
import Layout from "./styles/layout.styles";
import { useSocket } from "../../contexts/socket-provider";

function UserCard({ user, isExpanded }) {
  const { updateSelectedUser, selectedUser } = useSocket();

  function handleUserCardClick() {
    updateSelectedUser(user);
  }

  return (
    <User onClick={handleUserCardClick}>
      <User.Avatar
        isSelected={selectedUser?.userId === user.userId}
        hasNewMessages={user.hasNewMessages}
      >
        {getMonogram(user.username)}
        <User.Status isActive={user.connected} />
      </User.Avatar>

      {isExpanded && <User.Name>{user.username}</User.Name>}
    </User>
  );
}

export default function UserList({ users, isExpanded }) {
  return (
    <Layout.UserList>
      <Layout.UserListHeader>Users</Layout.UserListHeader>
      {users.map((user) => (
        <UserCard key={user.userId} user={user} isExpanded={isExpanded} />
      ))}
    </Layout.UserList>
  );
}
