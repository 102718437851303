import { useTranslation } from "react-i18next";
import PageLayout from "../layouts/page-layout";

export default function TermsPage() {
  const { t } = useTranslation();

  return (
    <PageLayout>
      <h3>{t("page.terms.title")}</h3>
      <p>{t("page.terms.bodyOne")}</p>
      <p>{t("page.terms.bodyTwo")}</p>
      <p>{t("page.terms.bodyThree")}</p>
      <p>{t("page.terms.bodyFour")}</p>
      <p>{t("page.terms.bodyFive")}</p>
      <p>{t("page.terms.bodySix")}</p>
      <p>{t("page.terms.bodySeven")}</p>
      <p><a href="locales/adatkezelesiTajekoztatoHegyvidek.pdf">{t("page.terms.gdprLink")}</a></p>
   </PageLayout>
  );
}
