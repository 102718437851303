import styled from "styled-components";

export const Container = styled.div`
  display: block;
  color: ${({ theme }) => theme.palette.main};
  font-family: Rubik, sans-serif;
  flex: 0 1 calc(100% - 1em);
  text-decoration: none;
  border: 3px solid ${({ theme }) => theme.palette.main};
  border-radius: 3px;
  box-shadow: 6px 6px #e8dfdb;
  background-color: white;
  margin-bottom: 0.5rem;
  padding: 1rem;
`;

export const Row = styled.div`
  display: flex;
  justify-content: ${(props) => (props.between ? "space-between" : "normal")};
  align-items: ${({ alignItemsCenter }) => (alignItemsCenter ? "center" : "normal")};
  gap: ${({ gap }) => (gap ? `${gap}rem` : "0.5rem")};
`;

export const Gap = styled.div`
  display: flex;
  gap: ${(props) => (props.size ? `${props.size}rem` : "0.5rem")};
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const VerticalSpacer = styled.div`
  padding: ${(props) => (props.size ? `${props.size}rem 0` : "0.5rem 0")};
`;

export const HorizontalSpacer = styled.div`
  padding: ${(props) => (props.size ? `0 ${props.size}rem` : "0 0.5rem")};
`;

export const Overflow = styled.div`
  overflow: auto;
`;

export const OverflowX = styled.div`
  overflow-x: auto;
`;

export const OverflowY = styled.div`
  overflow-y: auto;
`;

export const Button = styled.button`
  background-color: white;
  text-align: center;
  padding: 10px 15px;
  font-weight: 700;
  border: 3px solid ${({ theme }) => theme.palette.main};
  border-radius: 3px;
  transition: background-color ${({ theme }) => theme.transition.medium};
  cursor: pointer;
  color: ${({ theme }) => theme.palette.main};

  :hover {
    background-color: ${({ theme }) => theme.palette.secondary};
  }

  :disabled {
    cursor: not-allowed;
    background-color: rgba(239, 239, 239, 0.3);
    color: rgba(16, 16, 16, 0.3);
    border-color: rgba(118, 118, 118, 0.3);
  }
`;

export const Select = styled.select`
  font-size: 16px;
  border-color: #402b2199;
  border-width: 3px;
  border-radius: 3px;
  background-color: #ffffff;
  height: 44px;
  padding: 5px 0.5rem;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  :hover,
  :focus {
    border-color: #402b21;
    border-width: 3px;
    border-radius: 3px;
    box-shadow: none;
  }
`;

export const Chip = styled.button`
  height: 32px;
  cursor: pointer;
  padding: 0 15px;
  font-weight: 500;
  border: 2px solid ${({ theme }) => theme.palette.main};
  border-radius: 16px;
  background-color: ${({ theme, active }) => (active ? theme.palette.secondary : "transparent")};
  transition: background-color ${({ theme }) => theme.transition.medium};

  :hover {
    background-color: ${({ theme }) => theme.palette.secondary};
  }
`;
