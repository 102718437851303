import sumBy from "lodash/sumBy";
import groupBy from "lodash/groupBy";
import ProgressBar from "../components/progress-bar";

import { isDefined, isString } from "./validation";

export function getMonogram(username) {
  if (!isDefined(username)) return "N/A";
  if (!isString(username)) return "N/A";

  const name = username.split(" ");
  if (name.length === 1) return name[0][0];
  return `${name[0][0]}${name[1][0]}`;
}

export function getGamesHistoryInTableForm(gamesList, t) {
  const unmappedGames = gamesList.map((game) => game.data);
  const games = groupBy(unmappedGames, "gameName");
  const mappedGames = Object.keys(games).map((game) => ({
    game: t(`games.${game}.title`),
    numberOfGamesPlayed: games[game].length,
    averagePoints: (
      <ProgressBar
        progress={Math.floor(sumBy(games[game], "displayedScore") / games[game].length)}
      />
    )
  }));

  return mappedGames;
}
