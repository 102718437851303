import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Center } from "../common/styles";

export default function ErrorPageContent({ disableBackButton, ...rest }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function goBack() {
    navigate(-1);
  }

  return (
    <Center {...rest}>
      <img
        src="/images/nav/nav-profile-logout.png"
        alt="player icon with a red background"
        style={{ width: 40 }}
      />
      <h3>{t("page.error.title")}</h3>
      <p style={{ marginBottom: 0 }}>{t("page.error.body")}</p>
      <p>{t("page.error.contact")}</p>
      {!disableBackButton && (
        <Button onClick={goBack} style={{ marginBottom: 35, marginTop: 35 }}>
          {t("page.error.back")}
        </Button>
      )}
    </Center>
  );
}
