import { useTranslation } from "react-i18next";
import useExperiments from "../../hooks/use-experiments";
import { getMonogram } from "../../utils/helper";
import { Row } from "../common/styles";
import Widget from "./widget.styles";

function QuestionnaireWidgetEntry({ entry, handleClick, handleInfoClick }) {
  const { t } = useTranslation();
  return (
    <Widget.Item alignItemsCenter between onClick={() => handleClick(entry)}>
      <Row alignItemsCenter>
        <Widget.Avatar>{getMonogram(t(`games.${entry}.title`))}</Widget.Avatar>
        {t(`games.${entry}.title`)}
      </Row>
      <Widget.ItemInfo onClick={(event) => handleInfoClick(event, entry)}>i</Widget.ItemInfo>
    </Widget.Item>
  );
}

export default function QuestionnaireWidget({ handleClick, handleInfoClick }) {
  const { t } = useTranslation();
  const { experimentQuestionnaires } = useExperiments();
  return (
    <Widget>
      <Widget.Title>{t("page.experiments.questionnaire")}</Widget.Title>
      {experimentQuestionnaires.map((experimentQuestionnaire) => (
        <QuestionnaireWidgetEntry
          key={experimentQuestionnaire}
          entry={experimentQuestionnaire}
          handleClick={handleClick}
          handleInfoClick={handleInfoClick}
        />
      ))}
    </Widget>
  );
}
