import { useEffect, useMemo, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getGamesHistoryInTableForm, getMonogram } from "../utils/helper";
import PageLayout from "../layouts/page-layout";
import useGamesHistory from "../hooks/use-games-history";
import { Button, Container, Gap, Row, VerticalSpacer } from "../components/common/styles";
import { DataTable } from "../components/data-table";
import Spinner from "../components/spinner";
import ErrorPageContent from "../components/contents/error-page-content";
import { isDefined, isValidGame } from "../utils/validation";

const Avatar = styled.div`
  height: 60px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7c4b8;
  border-radius: 5px;
`;

const Header = styled.h2`
  margin-top: 0;
`;

function ProfileDetail({ title, value }) {
  return (
    <div>
      <span>{title}:</span>
      <p style={{ marginTop: 6, marginBottom: 18 }}>
        <strong>{value}</strong>
      </p>
    </div>
  );
}

function ProfileGamesHistory() {
  const { t } = useTranslation();
  const playerId = JSON.parse(localStorage.getItem("flags"))?.session?.playerId ?? null;
  const { gamesHistory, isLoading, isError } = useGamesHistory(playerId);

  const columns = useMemo(
    () => [
      {
        Header: t("table.gameHistory.name"),
        accessor: "game"
      },
      {
        Header: t("table.gameHistory.numberOfGamesPlayed"),
        accessor: "numberOfGamesPlayed"
      },
      {
        Header: t("table.gameHistory.averagePoints"),
        accessor: "averagePoints"
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const gamesHistoryTableData = useMemo(() => {
    if (!gamesHistory) return [];
    const filteredGameHistory = gamesHistory.content.filter(
      (game) => isValidGame(game.data.gameName) && !game.data.interrupted
    );
    return getGamesHistoryInTableForm(filteredGameHistory, t);
  }, [gamesHistory, t]);

  if (isLoading) return <Spinner />;
  if (isError) return <ErrorPageContent />;
  if (!gamesHistory || !isDefined(gamesHistory.content)) return "";

  return (
    <DataTable
      title={t("page.profile.gameHistory")}
      data={gamesHistoryTableData}
      columns={columns}
    />
  );
}

export default function ProfilePage() {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const [userProfile, setUserProfile] = useState({});

  function getFormattedAgeFromYear(year) {
    return `${year} (${new Date().getFullYear() - year})`;
  }

  useEffect(() => {
    if (keycloak.authenticated) {
      keycloak.loadUserProfile().then((profile) => setUserProfile(profile));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLayout>
      <h1>{t("page.profile.title")}</h1>
      <Container>
        <Row>
          <Gap size={2}>
            <div>
              <Avatar>{getMonogram(userProfile.username)}</Avatar>
              <VerticalSpacer />
              <Button onClick={() => keycloak.accountManagement()}>{t("page.profile.edit")}</Button>
            </div>
            <div>
              <Header>{userProfile.username}</Header>
              <ProfileDetail title={t("page.profile.email")} value={userProfile.email} />
              <ProfileDetail
                title={t("page.profile.education")}
                value={t(`page.profile.${userProfile?.attributes?.education}`)}
              />
              <ProfileDetail
                title={t("page.profile.birthyear")}
                value={getFormattedAgeFromYear(userProfile?.attributes?.birthyear)}
              />
              <ProfileDetail
                title={t("page.profile.gender")}
                value={t(`page.profile.${userProfile?.attributes?.gender}`)}
              />
            </div>
          </Gap>
        </Row>
      </Container>
      <ProfileGamesHistory />
    </PageLayout>
  );
}
