import { useTranslation } from "react-i18next";
import { Center } from "../common/styles";

export default function RequireLoginContent() {
  const { t } = useTranslation();

  return (
    <Center>
      <img
        src="/images/nav/nav-profile-logout.png"
        alt="player icon with a green background"
        style={{ width: 40 }}
      />
      <h3>{t("page.error.authentication.title")}</h3>
      <p>{t("page.error.authentication.body")}</p>
    </Center>
  );
}
