import { useTranslation } from "react-i18next";
import { Button, Gap, Row } from "../common/styles";
import { fileTypeMapper, isDefined } from "../../utils/validation";
import { DOCUMENT_EXTENSION_CSV, DOCUMENT_EXTENSION_JSON } from "../../utils/constants";

function extractRowData(selectedRows) {
  return selectedRows.map((selectedRow) => selectedRow.original);
}

function download(content, fileName, contentType) {
  var a = document.createElement("a");
  var file = new Blob([content], {
    ...(isDefined(contentType) && { type: fileTypeMapper[contentType] })
  });
  a.href = URL.createObjectURL(file);
  a.download = `${fileName}${contentType}`;
  a.click();
}

function exportAsJson(selectedRows) {
  if (!isDefined(selectedRows)) return;

  download(JSON.stringify(selectedRows), "subjects", DOCUMENT_EXTENSION_JSON);
}

function exportAsCsv(selectedRows) {
  if (!isDefined(selectedRows)) return;

  const replacer = (_, value) => (value === null ? "" : value);
  const header = Object.keys(selectedRows[0]);
  const convertedRowData = [
    header.join(","),
    ...selectedRows.map((row) =>
      header
        .map((fieldName) => {
          return JSON.stringify(row[fieldName], replacer);
        })
        .join(",")
    )
  ].join("\r\n");

  download(convertedRowData, "subjects", DOCUMENT_EXTENSION_CSV);
}

export default function TableHeader({ title, selectedRows = null }) {
  const { t } = useTranslation();

  if (selectedRows === null)
    return (
      <Row>
        <h3>{title}</h3>
      </Row>
    );

  return (
    <Row between>
      <h3>{title}</h3>
      <Gap>
        <Button
          onClick={() => exportAsCsv(extractRowData(selectedRows))}
          disabled={!isDefined(selectedRows)}
        >
          {t("action.export.csv")}
        </Button>
        <Button
          onClick={() => exportAsJson(extractRowData(selectedRows))}
          disabled={!isDefined(selectedRows)}
        >
          {t("action.export.json")}
        </Button>
      </Gap>
    </Row>
  );
}
