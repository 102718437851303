import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import urlcat from "urlcat";
import { Button, Container, Row } from "../components/common/styles";
import Spinner from "../components/spinner";
import PageLayout from "../layouts/page-layout";
import { languageMapper } from "../utils/validation";
import useGameInfo from "../hooks/use-game-info";
import ErrorPageContent from "../components/contents/error-page-content";
import Game from "../components/game/game-card.styles";

export default function GameInfoPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { gameInfo, isLoading, isError } = useGameInfo(id);

  function handleStartClick() {
    navigate(`/games/${id}`);
  }

  function handleBackClick() {
    navigate(-1);
  }

  const gameImageUri = `/games/${id}/res/img/icon.png`;
  const gameStatUri = useMemo(
    () =>
      urlcat("/game-stats.html", {
        gameName: id,
        lang: languageMapper[i18n.resolvedLanguage]
      }),
    [i18n.resolvedLanguage, id]
  );

  if (gameStatUri === "") {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  if (isLoading) return <Spinner />;
  if (isError) return <ErrorPageContent />;

  return (
    <PageLayout>
      <h3>{t(`games.${id}.title`)}</h3>
      <Container style={{ padding: 0 }}>
        <Row
          style={{
            display: "grid",
            gridTemplateColumns: "250px 1fr"
          }}
        >
          <div
            style={{
              padding: 24,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Game.Image src={gameImageUri} />
            <div>
              <Button onClick={handleBackClick} style={{ marginTop: 12, marginRight: 6 }}>
                {t("page.games.back")}
              </Button>
              <Button onClick={handleStartClick} style={{ marginTop: 12 }}>
                {t("page.games.play")}
              </Button>
            </div>
          </div>
          <div>
            <h3>{t(`games.${id}.title`)}</h3>
            <p style={{ whiteSpace: "pre-wrap", lineHeight: "1rem" }}>{gameInfo}</p>
          </div>
        </Row>
        <Row>
          <iframe
            title={id}
            src={gameStatUri}
            style={{ width: "100%", flexGrow: 1, boxSizing: "border-box" }}
          />
        </Row>
      </Container>
    </PageLayout>
  );
}
