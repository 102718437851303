import { useKeycloak } from "@react-keycloak/web";
import useSWR from "swr";

const fetcher = (url, token) =>
  fetch(url, {
    mode: "cors",
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((res) => res.json());

export function useSubjects() {
  const { keycloak } = useKeycloak();
  const keycloakEndpointUri = `${window.__RUNTIME_CONFIG__.REACT_APP_KEYCLOAK_API_URI}/${window.__RUNTIME_CONFIG__.REACT_APP_KEYCLOAK_REALM}/users`;

  const { data, error } = useSWR([keycloakEndpointUri, keycloak.token], fetcher);

  return {
    subjects: data,
    isLoading: !error && !data,
    isError: error
  };
}
