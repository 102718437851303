import styled from "styled-components";
import { Link } from "react-router-dom";

const GameLink = styled(Link)`
  width: 100%;
  color: ${({ theme }) => theme.palette.main};
  background-color: ${({ theme }) => theme.palette.primary};
  border-top: 2px solid ${({ theme }) => theme.palette.main};
  border-left: 1px solid ${({ theme }) => theme.palette.main};
  padding: 0.75rem;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  transition: all ease-in-out 150ms;

  :hover {
    background-color: #f29e8a;
  }

  :first-of-type {
    border-left: 0;
    border-right: 1px solid ${({ theme }) => theme.palette.main};
  }
`;

const GameImage = styled.div`
  display: block;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.secondary};
  background-image: ${({ src }) => `url(${src})`};
  margin-top: 1rem;
  align-self: center;
`;

const GameTitle = styled.h4`
  display: flex;
  align-self: center;
`;

const Game = styled.div`
  display: flex;
  flex: 0 1 calc(25% - 1em);
  flex-direction: column;
  font-family: Rubik, sans-serif;
  color: #402b21;
  background-color: white;
  border: 3px solid #402b21;
  border-radius: 3px;
  margin-bottom: 1rem;
  text-decoration: none;
  box-shadow: 6px 6px #e8dfdb;
  transition: all ease-in-out 150ms;

  :hover {
    background-color: #eee;
    box-shadow: 7px 10px #e8dfdb;
    transform: translateY(-2px);
  }
`;

Game.Link = GameLink;
Game.Title = GameTitle;
Game.Image = GameImage;

export default Game;
