import useSWR from "swr";
import urlcat from "urlcat";

const fetcher = (url) =>
  fetch(url, {
    mode: "cors"
  }).then((res) => res.json());

export default function useGamesHistory(id) {
  const gamesHistoryApiUri = urlcat("/api/gameplays", {
    playerId: id,
    size: 99999,
    sort: "startTimeEpoch"
  });

  const { data, error } = useSWR(gamesHistoryApiUri, fetcher);

  return {
    gamesHistory: data,
    isLoading: !error && !data,
    isError: error
  };
}
