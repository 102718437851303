import styled from "styled-components";

function getProgressInPercentage(progress) {
  const maxProgress = 1000;
  return (progress * 100) / maxProgress;
}

const ProgressBarContainer = styled.div`
  border: 3px solid ${({ theme }) => theme.palette.main};
`;

const ProgressBarFill = styled.div`
  display: block;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.primary};
  width: ${({ percentage }) => percentage}%;
  padding: 6px;
`;

export default function ProgressBar({ progress }) {
  const percentage = getProgressInPercentage(progress);

  return (
    <ProgressBarContainer>
      <ProgressBarFill percentage={percentage}>{progress}</ProgressBarFill>
    </ProgressBarContainer>
  );
}
