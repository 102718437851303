import { useTranslation } from "react-i18next";
import Game from "./game-card.styles";

export default function GameCard({ gameId }) {
  const { t } = useTranslation();

  const gameImageUri = `/games/${gameId}/res/img/icon.png`;

  return (
    <Game>
      <Game.Image src={gameImageUri} />
      <Game.Title>{t(`games.${gameId}.title`)}</Game.Title>
      <div style={{ display: "flex", width: "100%" }}>
        <Game.Link to={`/games/${gameId}/info`}>{t("page.games.info")}</Game.Link>
        <Game.Link to={`/games/${gameId}`}>{t("page.games.play")}</Game.Link>
      </div>
    </Game>
  );
}
