import styled from "styled-components";

const NavContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  color: black;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
`;

const NavList = styled.ul`
  display: ${(props) => (props.isExpanded ? "block" : "none")};
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  border-top: 1px solid black;
  background-color: #f5f2f0;
  padding: 0;
  margin: 0;
  z-index: 1010;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    background-color: #f5f2f0;
    border: none;
    padding: 0;
    margin-top: 1rem;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    position: static;
  }
`;

const NavItem = styled.li`
  background-color: ${({ active, theme }) => (active ? theme.palette.secondary : "transparent")};
  border-radius: 4px;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 5px;
  margin: 0;

  :hover {
    background-color: ${({ theme }) => theme.palette.transparent.secondary};
  }

  a {
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 1.5rem;

    @media screen and (min-width: 768px) {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 0;
    }
  }

  button {
    display: flex;
    color: inherit;
    background: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: none;
    padding: 1.5rem;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    @media screen and (min-width: 768px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  }

  @media screen and (min-width: 768px) {
    margin: 0 0.25rem;
  }
`;

const NavItemImage = styled.img`
  display: flex;
  width: 40px;
  height: 40px;
  padding-right: 1.5rem;

  @media screen and (min-width: 768px) {
    padding-right: 0;
  }
`;

const NavItemText = styled.span`
  margin-top: 0.5rem;
`;

const NavToggle = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  display: block;
  order: 3;

  span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 4px;
    position: relative;
    background: #402b21;
    border-radius: 3px;
    z-index: 1;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const Nav = styled.table`
  width: 100%;
  border-spacing: 0;
  border-radius: 3px;
  border: 3px solid #402b21;
`;

Nav.Container = NavContainer;
Nav.List = NavList;
Nav.Item = NavItem;
Nav.Item.Image = NavItemImage;
Nav.Item.Text = NavItemText;
Nav.Toggle = NavToggle;

export default Nav;
