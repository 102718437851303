import { useState } from "react";
import { SocketProvider, useSocket } from "../../contexts/socket-provider";
import Layout from "./styles/layout.styles";
import ChatPanel from "./chat-panel";
import UserList from "./user-list";
import { Button } from "../common/styles";

function CollapseButton({ onClick, isExpanded }) {
  return (
    <Button
      style={{
        height: 54,
        width: 54,
        alignSelf: "end",
        marginRight: "0.75rem",
        marginBottom: "0.75rem",
        lineHeight: 1
      }}
      onClick={onClick}
    >
      {isExpanded ? ">" : "<"}
    </Button>
  );
}

function StatefulChat() {
  const { userList, socket } = useSocket();
  const [isExpanded, setIsExpanded] = useState(false);

  function handleButtonClick() {
    setIsExpanded((prev) => !prev);
  }

  if (!socket?.connected) return "";

  return (
    <>
      <Layout.Bottombar>
        <ChatPanel />
      </Layout.Bottombar>
      <Layout.Sidebar>
        <UserList users={userList} isExpanded={isExpanded} />
        <CollapseButton onClick={handleButtonClick} isExpanded={isExpanded} />
      </Layout.Sidebar>
    </>
  );
}

export default function Chat() {
  return (
    <SocketProvider>
      <StatefulChat />
    </SocketProvider>
  );
}
